<template>
    <div>
        <overtime-work-reason-list></overtime-work-reason-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  